//
// Plugins / Slim Scroll
//
// Version: 1.3.2
//
// --------------------------------------------------

.slimScrollBar {
  width: 6px !important;
  opacity: .3 !important;
}

.slimScrollRail {
  width: 6px !important;
}