//
// Plugins / jQuery UI / Datepickers
// --------------------------------------------------

// Datepicker
//

.ui-datepicker {
  background-clip: padding-box;
  background-color: #fff;
  border: 4px solid;
  border-color: #ccc; // IE fallback
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: none;
  left: 0;
  position: absolute;
  text-align: left;
  top: 0;
  width: auto !important;
  z-index: $zindex-popover;
  white-space: normal;

  table {
    width: 210px;
  }

  th,
  td {
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
  }

  td {
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;

    a {
      color: #555555;
      display: block;
      height: 100%;
      text-decoration: none;
      width: 100%;

      &:hover {
        background: #f3f3f3;
      }
    }

    &.ui-datepicker-today a {
      background: #f3f3f3;
      font-weight: 700;
    }
  }

  tr td:last-child {
    border-right: none;
  }

  th {
    color: #fff;
    font-weight: 600;
  }

  .ui-state-active {
    color: #fff !important;
    text-decoration: none;
  }
}


// Nav buttons
//

.ui-datepicker-prev,
.ui-datepicker-next {
  color: #fff;
  cursor: pointer;
  display: block;
  height: 35px;
  line-height: 35px;
  position: absolute;
  text-align: center;
  text-decoration: none !important;
  width: 35px;

  span {
    display: none;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &:after {
    color: #fff;
    font-family: FontAwesome;
  }
}

.ui-datepicker-prev {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  left: 0;

  &:after {
    content: "\f053";
  }
}

.ui-datepicker-next {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  right: 0;

  &:after {
    content: "\f054";
  }
}


// Datepicker title
//

.ui-datepicker-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: #fff;
  display: block;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 210px;

  select {
    background: none;
    border: none;
    color: #fff;

    &:first-child {
      margin-right: 5px;
    }
  }
}


// Button pane
//

.ui-datepicker-buttonpane {
  background: #eee;
  padding: 5px 7px;
  text-align: right;

  button {
    font-size: 12px;
    line-height: 15px;
    padding: 4px 9px;
    display: inline-block;

    font-weight: 400;
    text-decoration: none;
    outline: none !important;
    border: 1px solid #cfcece;
    border-radius: $border-radius-base;

    @include pixel-button($color: #f4f4f4, $gradient: 5%, $hover: 2%, $border: 4%, $font-color: $text-color);
  }

  button + button {
    margin-left: 5px;
  }
}


// Groups
//

.ui-datepicker-group {
  float: left;
  width: 210px;

  & + .ui-datepicker-group .ui-datepicker-title,
  & + .ui-datepicker-group table {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.ui-datepicker-row-break {
  @include clearfix();
}

