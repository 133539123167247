// --------------------------------------------------
// Plugins / Expanding Input
//

.expanding-input {
  position: relative;
}

.expanding-input-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
}

.expanding-input-placeholder {
  padding: ($padding-base-vertical + 1px) ($padding-base-horizontal + 1px);
  color: $input-color-placeholder;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.expanding-input-hidden {
  display: none;
}

.expanding-input.expanded .expanding-input-hidden,
.expanding-input.expanded .expanding-input-content {
  display: block;
  @include clearfix();
}

.expanding-input.expanding-input-sm .expanding-input-placeholder {
  padding: ($padding-small-vertical + 1px) ($padding-small-horizontal + 1px);
  font-size: $font-size-small;
  line-height: $line-height-small;
}

.expanding-input.expanding-input-lg .expanding-input-placeholder {
  padding: ($padding-large-vertical + 1px) ($padding-large-horizontal + 1px);
  font-size: $font-size-large;
  line-height: $line-height-large;
}
