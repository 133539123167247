//
// Tables
// --------------------------------------------------


// Tables
//

.table {
  // Cells
  thead,
  tbody,
  tfoot {
    > tr > th,
    > tr > td {
      border-top-color: $table-border-color;
    }

    > tr > th {
      font-weight: 600;
    }
  }
  // Account for multiple tbody instances
  tbody + tbody {
    border-top-color: $table-border-color;
  }
}

// Bordered tables
//

.table-bordered {
  border-color: $table-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr > th,
    > tr > td {
      border-color: $table-border-color;
    }
  }
}

// Table header
//

.table-header {
  background: #fff;
  position: relative;
  border: 1px solid $table-border-color;
  margin-bottom: -1px;
  padding: 10px 15px 10px 15px;
  @include border-top-radius($border-radius-base);

  .table-caption {
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
}

// Table footer
//

.table-footer {
  min-height: 40px;
  margin-top: -19px;
  position: relative;
  border: 1px solid $table-border-color;
  line-height: 20px;
  padding: 10px 15px 10px 15px;
  background: #fafafa;
  @include border-bottom-radius($border-radius-base);
}


// Colors
//

.table-light,
.table-success,
.table-danger,
.table-warning,
.table-info,
.table-primary {
  margin-bottom: 20px;
}

.table-light {
  @include table-color(#fafafa, $text-color);
}

.table-success {
  @include table-color($success-color);
}

.table-danger {
  @include table-color($danger-color);
}

.table-warning {
  @include table-color($warning-color);
}

.table-info {
  @include table-color($info-color);
}