//
// Badges
// --------------------------------------------------


// Default badges
//

.badge {
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  line-height: $label-tag-height - 2;
  padding: 0 8px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);

  &.pull-right {
    margin: 0;
  }
}


// Colors
//

.badge {
  @include pixel-badge(#b0b0b0);
}

.badge.badge-success {
  @include pixel-badge($success-color);
}

.badge.badge-warning {
  @include pixel-badge($warning-color);
}

.badge.badge-danger {
  @include pixel-badge($danger-color);
}

.badge.badge-info {
  @include pixel-badge($info-color);
}

