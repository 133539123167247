//
// Plugins / jQuery UI / Tabs
// --------------------------------------------------

.ui-tabs-nav {
  border-bottom: 2px solid $tabs-border-color !important;
}

.ui-tabs-nav.nav-tabs-simple > .ui-tabs-active > a {
  color: $text-color !important;
}

.ui-tabs-nav:not(.nav-tabs-simple) > .ui-tabs-active > a > .badge,
.ui-tabs-nav:not(.nav-tabs-simple) > .ui-tabs-active > a > .label {
  background-color: rgba(0,0,0,0.25);
  color: #fff;
}