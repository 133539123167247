// --------------------------------------------------
// Plugins / File Input
//

.pixel-file-input {
  display: block;
  width: 100%;
  height: 32px; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: 6px 12px;
  line-height: 18px;
  color: $text-color;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid $input-border;
  border-top-color: darken($input-border, 8%);
  border-radius: $input-border-radius;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  @include transition(border-color ease-in-out .15s);
}

.pixel-file-input > input[type=file] {
  visibility: hidden;
  position: absolute;
  display: block;
  left: -10000px;
}

.pfi-filename {
  white-space: nowrap;
}

.pfi-actions {
  display: block;
  position: absolute;
  right: 0;
  top: 4px;
  padding: 0 5px 0 10px;
  background: #fff;
  white-space: nowrap;

  > * + * {
    margin-left: 4px;
  }
}

.pfi-clear {
  display: none;
}

.pfi-placeholder {
  color: #aaa;
  font-style: italic;
}