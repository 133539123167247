//
// Button groups
// --------------------------------------------------

.btn-group-lg .btn {
  font-size: 15px;
  line-height: 19px;
  padding: 9px 18px;
}

.btn-group-sm .btn {
  font-size: 12px;
  line-height: 15px;
  padding: 4px 9px;

  > .fa-caret-down {
    font-size: 10px;
  }
}

.btn-group-xs .btn {
  font-size: 11px;
  line-height: 12px;
  padding: 4px 7px;

  > .fa-caret-down {
    font-size: 10px;
  }
}