//
// Modals
// --------------------------------------------------

// Modal dialog
.modal-dialog {
  $animation-duration: .8s;

  -webkit-animation-duration: $animation-duration;
  -moz-animation-duration: $animation-duration;
  -ms-animation-duration: $animation-duration;
  animation-duration: $animation-duration;
}

// Modal content
.modal-content {
  border: none;
  border-radius: $border-radius-base;
  @include box-shadow(0 1px 3px rgba(0, 0, 0, .1));
  @include clearfix();
}

// Modal header
.modal-header {
  background: lighten(#eee, 4%);
  padding: 12px 20px;
  @include border-top-radius($border-radius-base);
}

// Close button
.modal-dialog .close {
  color: $text-color;
  font-size: 20px;
  margin-top: 3px;

  &.fa-times {
    font-size: 15px;
    margin-top: 2px;
  }
}

// Modal footer
.modal-footer {
  padding: 12px 20px;
  margin-top: 0;
  background: #fff;
  @include border-bottom-radius($border-radius-base);
}


// Modal alerts
//

// Tablets
@media (min-width: $screen-small) {
  .modal-alert {
    .modal-dialog {
      width: 350px;
    }
  }
}

.modal-alert {
  .modal-header,
  .modal-title,
  .modal-body,
  .modal-footer {
    background:none;
    border: none;
    margin: 0;
    padding: 0 20px;
    text-align: center !important;
  }

  .modal-header {
    color: #fff;
    margin-bottom: 20px;
    padding: 25px 0 18px 0;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .15);
    border-bottom: 4px solid rgba(0, 0, 0, .08);

    .fa {
      font-size: 90px;
    }
  }

  .modal-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
  }

  .modal-body {
    color: #888;
  }

  .modal-footer {
    margin: 25px 0 20px 0;
  }

  // Colors
  //
  
  &.modal-info .modal-header {
    background: $info-color;
  }
  &.modal-danger .modal-header {
    background: $danger-color;
  }
  &.modal-warning .modal-header {
    background: $warning-color;
  }
  &.modal-success .modal-header {
    background: $success-color;
  }
}

