//
// Plugins / jQuery.DataTables
//
// Version: 1.10.0
//
// --------------------------------------------------


// Base
//

.dataTables_wrapper {
  .table-header {
    margin-bottom: -7px;
    padding: 13px 0 13px 15px;

    .table-caption {
      float: left;
      line-height: 20px;
      padding: 5px 10px 5px 0;
      width: auto;
    }
  }

  .table-footer {
    margin-top: -7px;
    padding-right: 10px;
  }

  .DT-label {
    color: #888;
    line-height: 29px;
  }
}

.DT-search,
.DT-per-page {
  display: inline-block;
}

.DT-lf-right {
  float: right;
  white-space: nowrap;
}


// Header
//

.DT-search {
  line-height: 30px;
  margin-right: 10px;

  &,
  input {
    width: 130px !important;
  }

  label,
  input {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.DT-per-page {
  border-right: 1px solid #eee;
  margin-right: 10px;
  height: 30px;
  padding-right: 10px;
  white-space: nowrap;

  select {
    display: inline-block !important;
    width: auto !important;
  }
}

.DT-label {
  float: left;
  font-size: 12px;
}


// Footer
//

.DT-pagination {
  float: right;
}

.dataTables_paginate {
  .pagination {
    > li {
      > a,
      > span {
        font-size: ceil(13px * 0.85);
        padding: 5px 10px;
      }
    }
  }
}


// Table
//

table.dataTable {
  clear: both;
  margin-bottom: 6px !important;
  margin-top: 6px !important;
  max-width: none !important;

  thead {
    .sorting,
    .sorting_asc,
    .sorting_desc,
    .sorting_asc_disabled,
    .sorting_desc_disabled {
      cursor: pointer;
    }

    .sorting:after { 
      content: "\f0dc";
      display: block;
    }

    .sorting_asc {
      background: rgba(0, 0, 0, .05);

      &:after {
        content: "\f0dd";
        display: block;
      }
    }

    .sorting_desc { 
      background: rgba(0, 0, 0, .05);

      &:after {
        content: "\f0de";
        display: block;
      }
    }

    .sorting_asc_disabled:after { 
      content: "\f0dd";
      display: block;
      opacity: .3;
    }

    .sorting_desc_disabled:after { 
      content: "\f0de";
      display: block;
      opacity: .3;
    }
  }

  th {
    position: relative;

    &:after {
      display: none;
      font-family: FontAwesome;
      font-size: 11px;
      font-weight: normal !important;
      line-height: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 8px;
      width: 20px;
    }

    &:active {
      outline: none;
    }
  }
}


// Scrolling
//

div.dataTables_scrollHead {
  table {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0 !important;

    thead tr:last-child th:first-child,
    thead tr:last-child td:first-child {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

div.dataTables_scrollBody {
  table {
    margin-bottom: 0 !important;
    border-top: none;
  }

  tbody tr:first-child th,
  tbody tr:first-child td {
    border-top: none;
  }
}

div.dataTables_scrollFoot table {
  border-top: none;
}


// Colors
//

.table-primary,
.table-success,
.table-danger,
.table-warning,
.table-info {
  .dataTables_wrapper .table-header {
    input,
    select {
      border: none !important;
    }
  }
}

.table-success {
  @include DT-color($success-color);
}

.table-danger {
  @include DT-color($danger-color);
}

.table-warning {
  @include DT-color($warning-color);
}

.table-info {
  @include DT-color($info-color);
}
