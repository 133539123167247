//
// Plugins / jQuery UI / Tooltips
// --------------------------------------------------

.ui-tooltip {
  background-color: $tooltip-bg;
  border-radius: $border-radius-base;
  color: #fff;
  font-size: 12px;
  max-width: 300px;
  padding: 4px 10px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  z-index: 9999;
}

body .ui-tooltip {
  border-width: 0;
}