//
// Plugins / jQuery.Sparkline
//
// Version: 2.1.2
//
// --------------------------------------------------


.jqstooltip {
  width: auto !important;
  height: auto !important;
  color: #fff !important;
  border-radius: $border-radius-base;
  background-color: $tooltip-bg !important;
  font-size: 11px !important;
  padding: 4px 10px !important;
}
