//
// Plugins / Bootstrap-editable / Typeahead
//
// Version: 1.5.1
//
// --------------------------------------------------

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;
}

.tt-dropdown-menu {
  border: 2px solid #ccc;
  padding: 5px 0;

  min-width: 160px;
  margin-top: 2px;

  background-color: #fff;
  border-radius: $border-radius-base;
}

.tt-suggestion {
  display: block;
  margin: 0 -2px;
  padding: 5px 18px;
}

.tt-suggestion p {
  margin: 0;
}

.tt-suggestion.tt-is-under-cursor a,
.tt-suggestion.tt-is-under-cursor {
  color: #fff;
}
