//
// Plugins / jQuery UI / Common
// --------------------------------------------------

/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none;
}
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}
.ui-helper-clearfix:after {
  clear: both;
}
.ui-helper-clearfix {
  min-height: 0; /* support: IE7 */
}
.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter:Alpha(Opacity=0);
}

.ui-front {
  z-index: 100;
}

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
}


/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

