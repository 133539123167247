//
// Plugins / Bootbox
//
// --------------------------------------------------

@media (min-width: $screen-small) {
  .bootbox.bootbox-sm .modal-dialog {
    width: 300px;
  }
}

.bootbox .modal-footer {
  margin-top: 0;
}

.bootbox.modal-alert .modal-footer {
  margin: 25px 0 20px 0;
}