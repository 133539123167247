//
// Plugins / Dropzone
//
// Version: 3.8.4
//
// --------------------------------------------------

// Fallback
.dz-browser-not-supported {
  .dz-message {
    display: none !important;
  }

  &.dropzone-box {
    min-height: auto !important;
    border: none !important;
    border-radius: 0 !important;
    width: auto !important;
    cursor: default !important;
    padding: 0 !important;
    @include transition(none);
  }
}


// Base
//

.dropzone-box {
  position: relative;
  min-height: 284px;
  border: 3px dashed #ddd;
  border-radius: 3px;
  vertical-align: middle;
  width: 100%;
  cursor: pointer;
  padding: 0 15px 15px 0;
  @include transition(all .2s);
}

.dz-default.dz-message {
  color: $text-color;
  font-size: 20px;
  font-weight: 600;
  display: block;
  min-height: 70px;
  width: 300px;
  position: absolute;
  padding-left: 90px;
  padding-top: 12px;
  line-height: 22px;
  top: 50%;
  margin-top: -35px;
  left: 50%;
  margin-left: -150px;
  @include transition(all .2s);
}

.dz-text-small {
  font-size: 14px;
  font-weight: 400;
}

.dropzone-box .fa.fa-cloud-upload {
  display: block;
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  line-height: 70px;
  font-size: 32px;
  background: #eee;
  color: #aaa;
  border-radius: $border-radius-base;
  @include transition(all .2s);
}

.dropzone-box:hover .fa.fa-cloud-upload {
  color: #fff;
}

.dz-started .dz-default.dz-message {
  display: none;
}

.dz-started:hover .fa.fa-cloud-upload {
  background: #eee !important;
  color: #aaa !important;
}


// File preview
// 

.dz-preview {
  width: 160px;
  padding: 6px 10px 10px 10px;
  margin: 15px 0 0 15px;
  display: inline-block;
  cursor: default;
  border: 1px solid #ddd;
  border-radius: $border-radius-base;
  vertical-align: top;
}

.dz-filename {
  color: $text-color;
  overflow: hidden;
  padding-bottom: 2px;
}

.dz-size {
  padding-bottom: 8px;
}

.dz-thumbnail {
  width: 138px;
  height: 120px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
}

.dz-thumbnail > img {
  max-width: 100%;
  max-height: 120px;
}

.dz-preview .progress,
.dz-preview .progess-bar {
  height: 8px;
}

.dz-preview .progress {
  margin: 8px 0 0 0;
}

.dz-size {
  font-style: italic;
  color: #aaa;
  font-size: 11px;
}

.dz-size span {
  font-weight: 600;
}

.dz-remove {
  line-height: 30px;
  display: block;
  margin: 8px -11px -11px -11px;
  text-align: center;
  color: #666;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid transparent;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  @include transition(all .1s);
  @include border-bottom-radius($border-radius-base);

  &:hover {
    background: $danger-color;
    color: #fff;
    border-top-color: transparent;
    text-decoration: none;
  }
}

.dz-thumbnail-wrapper {
  margin: 0 -10px;
  padding: 10px;
  background: #f2f2f2;
  border: 1px solid #ddd;
  border-left: none;
  border-right: none;
  position: relative;
}

.dz-nopreview {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  color: #888;
}

.dz-thumbnail img[src] + .dz-nopreview {
  display: none;
}

.dz-error-mark,
.dz-success-mark,
.dz-error-message {
  display: none;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  text-align: center;
  background: rgba(0, 0, 0, .5);
  box-shadow: 0 0 30px rgba(0, 0, 0, .1) inset;
  @include transition(all .1s);
}

.dz-error-mark .fa,
.dz-success-mark .fa {
  line-height: 140px;
  font-size: 50px;
  color: #fff;
  font-weight: 400;
}

.dz-error-mark .fa {
  color: #fa6b59;
}

.dz-success-mark .fa {
  color: #9ed441;
}

.dz-error-message {
  color: #fff;
  font-weight: 600;
  padding: 20px 10px 0 10px;
}

.dz-error {
  .dz-error-mark {
    display: block;
  }

  .dz-error-message {
    display: none;
  }
  
  &:hover {
    .dz-error-mark {
      display: none;
    }

    .dz-error-message {
      display: block;
    }
  }
}

.dz-success .dz-success-mark {
  display: block;
}
