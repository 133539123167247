//
// Plugins / jQuery.Minicolors
//
// Version: 2.1.1
//
// --------------------------------------------------


// Base styles
//

.minicolors {
	position: relative;

	input[type=hidden] + .minicolors-swatch {
		height: 32px;
		position: static;
		cursor: pointer;
	}

	.minicolors-grid {
		position: relative;
		float: right;
		width: 150px;
		height: 150px;
		background: url("#{$images-path}/plugins/jquery.minicolors.png") -120px 0;
		cursor: crosshair;
		margin: 1px;
	}

	.minicolors-grid-inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 150px;
		height: 150px;
		background: none;
	}
}

.minicolors-swatch {
	position: absolute;
	vertical-align: middle;
	background: url("#{$images-path}/plugins/jquery.minicolors.png") -80px 0;
	cursor: text;
	padding: 0;
	margin: 0;
	display: inline-block;
}

.minicolors-swatch-color {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}


// Panel
//

.minicolors-panel {
	display: none;
	position: absolute;
	background: #fff;
	border: $popover-border-width solid $popover-border-color;
	border-radius: 3px;
	margin-top: 10px;
	z-index: $zindex-datepicker;
	@include box-shadow(none);

	&.minicolors-visible {
		display: block;
	}

	// Arrows
	&:after {
		border-bottom: 10px solid $popover-border-color;
		border-left:   10px solid transparent;
		border-right:  10px solid transparent;
		border-top:    0;
		content: '';
		display: block;
		position: absolute;
	}
}

.modal .minicolors-panel {
	z-index: $zindex-datepicker-modal;
}

.minicolors-slider,
.minicolors-opacity-slider {
	position: relative;
	float: right;
	width: 20px;
	height: 150px;
	background: white url("#{$images-path}/plugins/jquery.minicolors.png") 0 0;
	cursor: row-resize;
	margin: 1px 1px 1px 0;
}


// Variations
//

// Opacity
.minicolors-with-opacity {
	.minicolors-opacity-slider {
		display: block;
	}

	.minicolors-slider {
		margin-right: 22px;
	}
}

.minicolors-opacity-slider {
	background-position: -40px 0;
	position: absolute;
	right: 0;
	top: 0;
	display: none;
}

// Saturation
.minicolors-slider-saturation {
	.minicolors-grid {
		background-position: -420px 0;
	}

	.minicolors-grid-inner {
		background: url("#{$images-path}/plugins/jquery.minicolors.png") -270px 0;
	}

	.minicolors-slider {
		background-position: -60px 0;
	}
}

// Brightness
.minicolors-slider-brightness {
	.minicolors-grid {
		background-position: -570px 0;
	}

	.minicolors-grid-inner {
		background: black;
	}

	.minicolors-slider {
		background-position: -20px 0;
	}
}

// Wheel
.minicolors-slider-wheel {
	.minicolors-grid {
		background-position: -720px 0;
	}

	.minicolors-slider {
		background-position: -20px 0;
	}
}


// Panel positioning
//

.minicolors-position-top .minicolors-panel {
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 10px;
	&:after {
		border-bottom: 0;
		border-top: 10px solid $popover-border-color;
		bottom: -10 - $popover-border-width;
	}
}

.minicolors-position-right .minicolors-panel {
	right: 0;
	&:after { right: 4 - ($popover-border-width / 2); }
}

.minicolors-position-bottom .minicolors-panel {
	top: auto;
	&:after { top: -10 - $popover-border-width; }
}

.minicolors-position-left .minicolors-panel {
	left: 0;
	&:after { left: 4 - ($popover-border-width / 2); }
}


// Pickers
//

.minicolors-picker {
	position: absolute;
	top: 0;
	left: 0;
	width: 18px;
	height: 2px;
	background: white;
	border: solid 1px black;
	margin-top: -2px;
	@include box-sizing(content-box);
}

.minicolors-grid {
	.minicolors-picker {
		position: absolute;
		top: 70px;
		left: 70px;
		width: 12px;
		height: 12px;
		border: solid 1px black;
		border-radius: 10px;
		margin-top: -6px;
		margin-left: -6px;
		background: none;

		> div {
			position: absolute;
			top: 0;
			left: 0;
			width: 8px;
			height: 8px;
			border-radius: 8px;
			border: solid 2px white;
			@include box-sizing(content-box);
		}
	}
}


// Inline controls
//

.minicolors-inline {
	display: inline-block;

	.minicolors-panel {
		position: relative;
		top: auto;
		left: auto;
		box-shadow: none;
		z-index: auto;
		display: inline-block;
		border: none;
		margin: 0;
		background: none;
		
		&:after {
			display: none !important;
		}
	}
}


// Default theme
//

.minicolors-theme-default {
	.minicolors-swatch {
		top: 5px;
		left: 5px;
		width: 18px;
		height: 18px;	
	}

	.minicolors-input {
		height: 20px;
		width: auto;
		display: inline-block;
		padding-left: 26px;
	}

	&.minicolors {
		width: auto;
		display: inline-block;
	}

	&.minicolors-position-right {
		.minicolors-swatch {
			left: auto;
			right: 5px;
		}

		.minicolors-input {
			padding-right: 26px;
			padding-left: inherit;
		}
	}
}


// Bootstrap theme
//

.minicolors-theme-bootstrap {
	.minicolors-swatch {
		top: 3px;
		left: 3px;
		width: 26px;
		bottom: 3px;
		border-radius: 2px;
	}

	.minicolors-input {
		padding-left: 40px;
	}

	&.minicolors-position-right {
		.minicolors-swatch {
			left: auto;
			right: 3px;
		}

		.minicolors-input {
			padding-right: 40px;
			padding-left: 12px;
		}
	}
}
