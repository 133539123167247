// --------------------------------------------------
// Plugins / Limiter
//

.limiter-label {
  color: #888;
  padding-top: 3px;
  font-size: 12px;
}

.limiter-count {
  font-weight: 600;
  color: $text-color;
}