//
// Plugins / Bootstrap-Markdown
//
// Version: 2.2.1
//
// --------------------------------------------------


.md-editor {
  display: block;
  border: 1px solid $table-border-color;
  border-radius: $border-radius-base;
  @include transition(all .3s);

  > .md-header, .md-footer {
    display: block;
    padding: 6px 4px;
    background: #f5f5f5;
  }

  > .md-header {
    margin: 0;
    @include border-top-radius($border-radius-base);

    .btn {
      padding-top: 5px;
      padding-bottom: 5px;

      .fa {
        font-size: 14px;
      }
    }
  }

  > .md-preview {
    border-top: 1px dashed $table-border-color;
    min-height: 10px;
    padding: $padding-base-vertical $padding-base-horizontal;
  }

  > textarea {
    font-family: $font-family-monospace;
    font-size: $font-size-base;
    outline: 0;
    margin: 0;
    display: block;
    padding: $padding-base-vertical $padding-base-horizontal;
    width: 100%;
    border: 0;
    border-top: 1px dashed $table-border-color;
    border-radius: 0;
    background: $input-bg;
  }

  &.active {
    border-color: $input-border-focus;
  }
}
