//
// Plugins / Easy Pie Chart
//
// Version: 2.1.5
//
// --------------------------------------------------

.pie-chart {
  display: inline-block !important;
  position: relative !important;
  @include clearfix();
}

.pie-chart-label {
  display: block !important;
  width: 100%;
  text-align: center;
  line-height: 40px;
  height: 40px;
  margin-top: -20px;
  position: absolute !important;
  top: 50%;
  font-weight: 300;
  font-size: 17px;
}

