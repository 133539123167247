// --------------------------------------------------
// Plugins / Wizard
//


// Base styles
//

.pxajs .wizard-pane {
  display: none;
}

.wizard-wrapper + .wizard-content.panel {
  margin-top: -1px;
}

.wizard.freeze .wizard-steps > li {
  cursor: default !important;
}

.modal-content > .wizard .wizard-wrapper {
  border-left: none;
  border-radius: 0;
  border-right: none;
  border-top: none;
}


// Wizard steps
//

// Wrapper
.wizard-wrapper {
  border: 1px solid $table-border-color;
  border-radius: $border-radius-base;
  white-space: nowrap;
  width: auto;
  position: relative;
  overflow: hidden;
}

// Steps
.wizard-steps {
  cursor: default;
  display: block !important;
  float: left;
  margin: 0;
  padding: 0;
  position: relative;
  white-space: nowrap;
  @include transition(left .3s);
  @include user-select-none();

  & > li {
    display: inline-block;
    list-style: none;
    margin: 0 0 0;
    padding: 0 10px 0 (($wizard-step-number-size + ($wizard-step-number-border-width * 2)) + ($wizard-step-number-margin * 2));
    vertical-align: middle;

    // Step border
    & + li:before {
      background: $table-border-color;
      bottom: 0;
      content: "";
      margin-left: -$wizard-step-number-margin - 41px;
      position: absolute;
      top: 0;
      width: 1px;
    }
  }

  // Completed step
  & > li.completed {
    cursor: pointer;
  }

  // Active and completed steps
  & > li.active,
  & > li.completed {
    .wizard-step-caption {
      color: $text-color;
    }

    .wizard-step-description {
      color: #888;
    }

    .wizard-step-number {
      border-color: $text-color;
      color: $text-color;
    }
  }

  .wizard-step-number:after {
    display: none;
  }
}

.wizard-step-number,
.wizard-steps > li.completed .wizard-step-number:after {
  background: #fff;
  border-radius: 9999px;
  display: block;
  font-size: 14px;
  line-height: $wizard-step-number-size;
  position: absolute;
  text-align: center;
}

// Step number
.wizard-step-number {
  border: $wizard-step-number-border-width solid #bbb;
  color: #bbb;
  font-weight: 700;
  height: $wizard-step-number-size + ($wizard-step-number-border-width * 2);
  margin-left: -$wizard-step-number-margin - 30px;
  margin-top: -(($wizard-step-number-size + ($wizard-step-number-border-width * 2)) / 2);
  top: 50%;
  width: $wizard-step-number-size + ($wizard-step-number-border-width * 2);
}

.wizard-steps > li.completed .wizard-step-number {
  font-size: 0;
}

// Show icon
.wizard-steps > li.completed .wizard-step-number:after {
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  height: $wizard-step-number-size;
  left: 0;
  width: $wizard-step-number-size;
  top: 0;
}


// Wizard captions and descriptions
//

.wizard-step-caption,
.wizard-step-description {
  color: #bbb;
  display: inline-block;
  line-height: 14px;
  white-space: normal;
}

.wizard-step-caption {
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 15px;
  vertical-align: middle;
}

.wizard-step-description {
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  position: relative;
}


// Wizard content
//

.wizard-content {
  padding: 20px;
  @include clearfix();
}
