.table > thead > tr > td.subtotal,
.table > thead > tr > th.subtotal,
.table > thead > tr.subtotal > td,
.table > thead > tr.subtotal > th,
.table > tbody > tr > td.subtotal,
.table > tbody > tr > th.subtotal,
.table > tbody > tr.subtotal > td,
.table > tbody > tr.subtotal > th,
.table > tfoot > tr > td.subtotal,
.table > tfoot > tr > th.subtotal,
.table > tfoot > tr.subtotal > td,
.table > tfoot > tr.subtotal > th {
  border-bottom-width: 3px;
  font-weight: 600;
}

.table > thead > tr > td.total,
.table > thead > tr > th.total,
.table > thead > tr.total > td,
.table > thead > tr.total > th,
.table > tbody > tr > td.total,
.table > tbody > tr > th.total,
.table > tbody > tr.total > td,
.table > tbody > tr.total > th,
.table > tfoot > tr > td.total,
.table > tfoot > tr > th.total,
.table > tfoot > tr.total > td,
.table > tfoot > tr.total > th {
  border-bottom-width: 3px;
  font-weight: 600;
}