//
// Plugins / jQuery UI / Menus
// --------------------------------------------------

.ui-menu {
  background-clip: padding-box;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: $border-radius-base;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  font-size: $font-size-base;
  left: 0;
  list-style: none;
  margin: 2px 0 0; // override default ul
  min-width: 160px;
  padding: 5px 0;
  position: absolute;
  z-index: $zindex-dropdown;

  // Submenus
  .ui-menu {
		margin-top: -3px;
		position: absolute;
	}

  // Menu item
	.ui-menu-item {
		list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    margin: 0;
    padding: 0;
    width: 100%;
    /* support: IE10, see #8844 */
	
    a {
      clear: both;
      color: $text-color;
      cursor: pointer;
      display: block;
      font-weight: normal;
      line-height: 1.428571429;
      margin: 0 -2px;
      min-height: 0; /* support: IE7 */
      padding: 5px 18px;
      text-decoration: none;
      white-space: nowrap; // prevent links from randomly breaking onto new lines

    	&:hover,
	  	&:focus {
	  	  color: #fff;
        text-decoration: none;
	  	}

      &[aria-haspopup="true"]:after {
        content: "\f0da";
        display: block;
        float: right;
        font-family: FontAwesome;
      }
		}
	}

  // Menu divider
	.divider,
  .ui-menu-divider {
    background-color: #e5e5e5;
    border-width: 2px;
    height: 1px;
    margin:5px 0;
    overflow: hidden;
	}

  // Disabled menu items
  .disabled,
	.ui-state-disabled {
		font-weight: normal;

		& a,
    &:hover a,
    &:focus a {
      background: transparent !important;
      color: #999 !important;
      cursor: not-allowed;
    }
	}
}

.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active {
	color: #fff;
}
