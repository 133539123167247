//
// Plugins / Bootstrap-Timepicker
//
// Version: 0.2.5
//
// --------------------------------------------------


.bootstrap-timepicker {
  position: relative;

  .input-group-addon { 
    cursor: pointer;
  }
}

// Timepicker widget
.bootstrap-timepicker-widget {
  // Modal window
  .modal-footer {
    margin-top: 0 !important;
  }

  // Timepicker dropdown
  &.dropdown-menu {
    left: 0;
    border: $popover-border-width solid $popover-border-color;
    border-radius: 3px;
    z-index: $zindex-datepicker;
    margin: 10px 0 0;
    width: 200px;
    padding: 10px;
    right: auto;
    @include box-shadow(none);

    &.timepicker-orient-bottom { margin-top: 0; }
    &.open { display: block; }

    // Arrows
    &:after {
      border-bottom: 10px solid $popover-border-color;
      border-left:   10px solid transparent;
      border-right:  10px solid transparent;
      border-top:    0;
      content: '';
      display: block;
      position: absolute;
    }
    &.timepicker-orient-left:after    { left: 12px - ($popover-border-width / 2); }
    &.timepicker-orient-right:after   { right: 12px - ($popover-border-width / 2); }
    &.timepicker-orient-top:after     { top: -10px - $popover-border-width; }
    &.timepicker-orient-bottom:after {
      border-bottom: 0;
      border-top:    10px solid $popover-border-color;
      bottom: -10px - $popover-border-width;
    }
  }

  span.bootstrap-timepicker-hour,
  span.bootstrap-timepicker-minute,
  span.bootstrap-timepicker-second,
  span.bootstrap-timepicker-meridian {
    line-height:  32px;
    font-weight: 600;
  }

  // Buttons
  .timepicker-increment{
    margin-bottom: 5px;
  }
  .timepicker-decrement {
    margin-top: 5px;
  }

  // Markup
  table {
    width: 100%;
    margin: 0;
    padding: 0;

    td { 
      text-align: center;
      height: auto;
      margin: 0;
      padding: 0;
      min-width: 20px;

      &.separator {
        width: 10px;
        text-align: center;
      }

      a,
      input {
        border-radius: $border-radius-base !important;
      }

      a {
        width: 100%;
        display: inline-block;
        margin: 0;
        padding: 0;
        border: 1px solid #eee;
        color: #666;
        text-decoration: none;
        line-height: 32px;
        text-align: center;

        &:hover {
          color: #fff;
        }

        .fa {
          margin: 0 !important;
          padding: 0 !important;
        }
      }

      input {
        position: relative;
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }
    }
  }
}

.modal .bootstrap-timepicker-widget.dropdown-menu {
  z-index: $zindex-datepicker-modal;
}