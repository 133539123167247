//
// Plugins / Bootstrap-Tabdrop
//
// --------------------------------------------------

.nav-tabs,
.nav-pills {
  position: relative;
}

.tabdrop {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.tabdrop > .dropdown-toggle {
  cursor: pointer !important;
  margin-right: 0;
  padding-left: 12px;
  padding-right: 12px;

  > .fa {
    font-size: 14px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  > .caret {
    border: none;
    display: inline-block;
    font-family: FontAwesome;
    font-size: 10px;
    height: auto;
    margin-left: 5px;
    position: relative;
    top: -1px;
    width: auto;

    &:after {
      content: "\f0d7";
    }
  }
}
