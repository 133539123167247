//
// Counters
// --------------------------------------------------

.counter,
.counter-separated {
  display: inline-block;
  
  // Number
  span {
    background: #444444;
    border: 1px solid #333;
    border-radius: $border-radius-base;
    color: #fff;
    display: block;
    float: left;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    padding: 0 6px;
    position: relative;
    text-align: center;
    
    // Box divider
    &:before {
      background: #333;
      content: " ";
      display: block;
      font-size: 0px;
      height: 1px;
      left: 0;
      position: absolute;
      top: 50%;
      width: 100%;
    }
  }


  // Sizes
  //

  &.counter-sm span {
    font-size: 16px;
    line-height: 22px;
    padding: 0 4px;
  }

  &.counter-lg span {
    font-size: 24px;
    line-height: 34px;
    padding: 0 6px;
  }
}

.counter-separated {
  // Number
  span {
    padding: 0 5px;

    + span {
      margin-left: 4px;
    }
  }

  // Small size
  &.counter-sm span {
    padding: 0 4px;

    + span {
      margin-left: 3px;
    }
  }
}
